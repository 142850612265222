







































































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import commonstore from "@/store/modules/common";
import { Stop } from "@/store/models/meta";
import metaStore from "@/store/modules/meta"

@Component({
  components: {}
})
export default class StopCreateView extends Vue {

  stop: Stop = {} as Stop

  async created() {
    const id = this.$route.query["id"];
    if (id) {
      await metaStore.loadStopById(id);
      this.stop = metaStore.stop;
    }
  }

  get AdminRouter() {
    return AdminRouter;
  }

  async submit() {
    const id = this.$route.query["id"];
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    commonstore.showLoading();
    if(id) {
      await metaStore.patchStop(this.stop);
      this.$snotify.success("Stop Changed Successfully");
    }
    else{
      await metaStore.createStop(this.stop);
      this.$snotify.success("Stop Created Successfully");
    }
    commonstore.hideLoading();
    this.$router.push(AdminRouter.Stop);
  }

  async cancelForm() {
    this.$router.push(AdminRouter.Stop);
  }
}
